@media only screen and (max-width: 945px) {

    .section {
        flex-direction: column;
    }

}

.section {
    width: 100%;
    padding: 20px 50px 0 50px;
}

/* .missionsList {
  background-color: white !important;
  padding: 10px;
}

.buttonRowWrapper > *:not(:last-child) {
  margin-right: 10px;
} */