.buttonWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0px auto;
}
.after {
  position: absolute;
  width: 50%;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: rgba(107, 98, 255, 0.8);
}
.slidingButton {
  text-align: center;
  width: 50%;
  padding: 10px;
  cursor: pointer;
}
.footerWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.footerWrapper .imgWrapper {
  max-width: 154px;
  height: 45px;
}

.footerWrapper img {
  width: 100%;
}
.microsoftBtn {
  display: flex !important;
  align-items: center;
  gap: 6px;
  margin: 0px auto;
}
