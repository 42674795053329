@media only screen and (max-width:945px) {

    .div :global(.ant-space) {
        flex-direction: column;
    }

    .div :global(.ant-space-item) {
        width: 100% !important;
    }

    .statusSelect {
        width: 100% !important;
    }


}

.statusSelect {
    width: 400px;
}