body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rbc-btn-group{
  margin-bottom: 10px
}
.rbc-toolbar-label{
  margin-bottom: 10px
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
iframe {
  display: none !important;
}
html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
.app {
  position: relative;
}
/* Global Styles */
.driverLogicInput {
  background-color: #000 !important;
  height: 50px !important;
  align-items: center !important;
  color: white !important;
  border: none !important;
}

.driverLogicInput > .ant-select-selector {
  background-color: #000 !important;
  height: 50px !important;
  align-items: center !important;
  padding: 0 25px !important;
  color: white !important;
  border: none !important;
}

.driverLogicInput input {
  height: 50px !important;
  padding: 0 15px !important;
  color: white !important;
}

.ant-picker-panel {
  width: 100%;
}

.driverLogicInput .ant-picker-suffix {
  color: white !important;
}

.driverLogicInput .ant-picker-clear path {
  background-color: transparent !important;
  fill: white !important;
}

.driverLogicInput .ant-picker-clear {
  background-color: transparent !important;
}

.ant-btn-primary {
  color: #fff !important;
  background: #6b62ff !important;
  border-color: #6b62ff !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-btn-primary:disabled {
  background-color: rgba(107, 98, 255, 0.8) !important;
}

/* Date and Time picker */
.ant-picker-datetime-panel {
  flex-direction: column;
}

.ant-picker-dropdown {
  width: 100%;
  max-width: 400px;
}

.ant-picker-datetime-panel > div {
  width: 100%;
}

.ant-picker-body {
  height: 260px;
}

.ant-picker-content {
  width: 100% !important;
  height: 100% !important;
}

.ant-picker-time-panel-column {
  height: 140px !important;
}
.spinner {
  width: 40px;
  height: 40px;
  margin: auto;
  background-color: white;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/* CUSTOM CSS */

.pagination-number-chip {
  min-width: 42px;
}
.pagination-number-chip:disabled {
  color: rgba(0, 0, 0, 0.85) !important;
  border-color: #d9d9d9 !important;
  cursor: unset !important;
  background-color: white !important;
}
.pagination-number-chip:disabled:hover {
  cursor: unset;
  color: rgba(0, 0, 0, 0.85);
}

/* ---------------- */

.user-test-section {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid red;
  display: inline-block;
}

.flex {
  display: flex;
}

table {
  table-layout: auto !important;
}

.ant-table-content {
  overflow-x: auto;
}

.rbc-allday-cell {
    display: none !important;
}

.rbc-time-content {
  border-top: 0 !important
}