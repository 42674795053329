.wrapper {
  background-color: rgba(180, 157, 157, 0.3);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
