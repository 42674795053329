.eventSmallWrapper {
  padding: 30px 0;
  cursor: pointer;
  transition: padding 0.2s, background-color 0.6s;
  border-bottom: 1px solid rgb(199, 199, 199);
}

.eventSmallWrapper:hover {
  background-color: #0000000e;
  padding: 30px;
}

.eventSmallDateRow {
  display: flex;
  justify-content: space-between;
}

.eventSmallDateRow > * {
  margin: 0 !important;
}

.eventSmallDateText {
  color: #6b62ff !important;
}
.innerWrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 320px) and (max-width: 568px) {
  .eventSmallDateRow,
  .innerWrapper {
    flex-direction: column;
    text-align: center;
  }
}
