@media only screen and (max-width: 568px) {

    .div :global(.ant-space) {
        flex-direction: column;
    }

    .div :global(.ant-space-item):last-child {
        width: 100% !important;
    }


}

@media only screen and (max-width: 740px) {

    .div :global(.ant-space-item):last-child {
        width: 100% !important;
    }


}

.div :global(.ant-space-item):last-child {
    width: 400px;
}