.tileWrapper {
  display: inline-grid;
  grid-gap: 20px;
  width: 100%;
  position: absolute;
  left: 0;
  overflow-x: auto;
  padding: 30px 30px 30px 20px;
  margin-top: -30px;
}
[data-framer-component-type="Frame"] {
  height: auto !important;
}
.tileWrapper::-webkit-scrollbar {
  width: 10px;
  margin-top: 12px;
  height: 10px;
}

.tileWrapper::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  display: none;
}

.tileWrapper::-webkit-scrollbar-thumb {
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: #afafaf;
  background-clip: padding-box;
}
