.layout {
  background-color: white !important;
}

.header {
  background-color: transparent !important;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentWrap {
  padding: 0 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}
