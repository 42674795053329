.tileWrap {
  width: 200px;
  height: 300px;
  border-radius: 7px;
  background: #f9f9f9;
  box-shadow: 1px 4px 6px 0px #0000002e;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.svgTile {
  width: 100%;
}

.tileWrap:hover {
  box-shadow: 2px 4px 18px 0px #00000021;
  transform: scale(1.04);
}
