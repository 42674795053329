.title {
  margin: 0;
  line-height: 2;
  color: #6b62ff !important;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.ballSymbol {
  width: 6px;
  height: 6px;
  background-color: #6b62ff;
  border-radius: 50%;
  margin-left: 15px;
  margin-right: 15px;
}

.routelink a {
  color: #6b62ff;
}
