.header {
  background-color: transparent !important;
  margin-top: 30px;
}

.contentWrap {
  padding: 0 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.tileRowWrap {
  display: inline-grid;
  grid-gap: 20px;
  grid-template-columns: 10px repeat(6, 225px) 10px;
  width: 100%;
  overflow: scroll;
  position: absolute;
  left: 0;
  padding: 30px 30px 30px 20px;
  margin-top: -30px;
}

.eventSmallListWrapper > .eventSmallWrapper:not(:last-child) {
  border-bottom: thin solid #e6e6e6;
}
