.footer {
  /* text-align: right; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.actionButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  margin-right: 60px;
  justify-content: space-between;

}

.actionButtons > * {
  margin: 0 10px;
}

 .cancel{
  display:grid;
  width: 160px;
  margin-left: 20px;
  margin-top: -10px;

} 

.assign{
  display:grid;
  grid-gap: 10px;
  margin-left: 20px;
}

.save{
  display:grid;
  width: 160px;
  margin-left: 20px;
  margin-top: -10px;
  grid-gap: 10px;
}

.approve{
  display:grid;
  width: 160px;
  margin-left: 20px;
}

.book{
  display:grid;
  width: 140px;
  margin-left: 20px;
}