.sideMenuOptionsList {
  color: white;
  padding: 0;
}

.sideMenuOptionsList li {
  display: flex;
  list-style-type: none;
  width: 100%;
  height: 60px;
  cursor: pointer;
  background-color: transparent;
  transition: padding 0.2s, background-color 0.6s;
}
.sideMenuOptionsList li > a {
  flex: 1;
  vertical-align: middle;
  color: white;
  display: flex;
  align-items: center;
}

.sideMenuOptionsList li > a:hover {
  background-color: rgba(255, 255, 255, 0.226);
}

.sideMenuOptionsList li:not(:last-child) {
  border-bottom: thin solid #ffffff3d;
}

.sideMenuOptionsList li:last-child {
  color: rgb(163, 144, 199);
}
